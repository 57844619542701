import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import BodyRenderer from 'common/BodyRenderer';
import { Layout } from 'layout';
import { ProductPageTypes } from '@shared/types/umbraco/content/productPage';
import ProductOverview from 'components/ProductOverview';
import AnchorMenu from 'components/AnchorMenu';
import RelatedArticles from 'components/RelatedArticles';
import SlimBanner from 'components/SlimBanner';
import ProductDetails from 'components/ProductDetails';
import ProductBenefits from 'components/ProductBenefits';
import ProductListingBanner from 'components/ProductListingBanner';
import FaqSection from 'components/FaqSection';
import './ProductPage.scss';
import WideBanner from 'components/WideBanner';
import GridList from 'components/GridList';
import InstructionsStepList from 'components/InstructionsStepList';
import HowToUse from 'components/HowToUse';
import SignUp from 'components/SignUp';
import ProductPageSchemaWrapper from 'common/ProductPageSchemaWrapper';
import { BV_SCRIPT_HOST } from 'utils/constants';
import { useBazaarVoice } from '@phx-husky/use-bazaarvoice';
import BazaarVoiceSchema from '@phx-husky/bazaarvoice-schema';
import BazaarvoiceReviewsSection from 'components/BazaarvoiceReviewsSection';

const bodyBlocks = {
  Overview: ({ properties }, keyId) => <ProductOverview key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  Benefits: ({ properties }, keyId) => <ProductBenefits key={keyId} {...properties} />,
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Image Text Grid': ({ properties }, keyId) => <GridList key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Related Articles': ({ properties }, keyId) => <RelatedArticles key={keyId} {...properties} />,
  'Instructions List': ({ properties }, keyId) => (
    <InstructionsStepList key={keyId} properties={properties} />
  ),
  'How To Use': ({ properties }, keyId) => <HowToUse key={keyId} properties={properties} />,
  'Anchor Menu Placeholder': ({ properties }, keyId) => <AnchorMenu key={keyId} {...properties} />,
  'Bazaarvoice Reviews': ({ properties }, keyId) => (
    <BazaarvoiceReviewsSection key={keyId} {...properties} />
  ),
};

const ProductPage: FC<ProductPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, relatedArticlesLinks, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          skuId,
          ean,
          shopifyId,
          title,
          shortDescription,
          bgImage,
          defaultCompositions: {
            footer,
            header,
            productSettings,
            waiSettings,
            singupFormBaner,
            siteSettings,
            brandSettings,
            purchaseSettings,
            warning,
          },
          body,
          anchorMenu,
          tags,
          availableTitle,
          availableLinks,
          images,
          productSize,
          productInfo,
          packagingType,
          productScent,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          masterProduct,
          alternateUrls,
          showBazaarvoiceReviewCta,
        },
      ],
    },
    relatedProducts,
    relatedArticles,
    productVariants,
  },
}) => {
  const { isShopify, lang, bvScript, isEanProductId, disableProductPageSchema } =
    siteSettings || {};
  const pageUrl = `${siteSettings?.siteUrl}${link}`;

  const productDataForBv = {
    productId: ean || skuId,
    productName: title,
    productPageURL: pageUrl,
    brandName: brandSettings?.brandName,
    productImageURL: images?.[0]?.properties?.image?.fallbackUrl,
  };

  const bvScriptUrl = `${BV_SCRIPT_HOST}/${bvScript}/bv.js`;
  const { trackCatalogUpdate } = useBazaarVoice(bvScriptUrl);
  const bvProductId = isEanProductId && ean ? ean : skuId || ean;

  useEffect(() => {
    trackCatalogUpdate([productDataForBv], lang);
  }, [productDataForBv]);

  const productDataForShopify = {
    nodes: [
      {
        skuId,
        shopifyId,
        productInfo,
        title,
        shortDescription,
        productSize,
        packagingType,
        productScent,
        images,
      },
    ],
  };

  return (
    <Layout
      {...{
        footer,
        header,
        seoMetaKeywords,
        seoMetaTitle,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        siteSettings,
        brandSettings,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        warning,
        link,
        searchUrl,
        alternateUrls,
        imageUrl: images?.[0]?.properties?.image?.fallbackUrl,
        imageAlt: images?.[0]?.properties?.imageAlt,
      }}
    >
      {disableProductPageSchema ? null : (
        <ProductPageSchemaWrapper
          brandSettings={brandSettings}
          siteSettings={siteSettings}
          title={title}
          productSize={productSize}
          productScent={productScent}
          packagingType={packagingType}
          images={images}
          shortDescription={shortDescription}
          link={link}
          skuId={skuId}
          shopifyId={shopifyId}
          isShopify={isShopify}
          lang={lang}
        />
      )}
      <BazaarVoiceSchema
        productUrl={pageUrl}
        name={title}
        image={images?.[0]?.properties?.image?.fallbackUrl}
        description={shortDescription || `product ${title} `}
        sku={skuId || ean}
        brand={brandSettings?.brandName}
      />
      <ProductDetails
        {...{ ...productSettings, ...siteSettings }}
        skuId={skuId || ean}
        ean={ean}
        shopifyId={shopifyId}
        title={title}
        images={images}
        shortDescription={shortDescription}
        breadCrumbs={breadCrumbs}
        tags={tags}
        bgImage={bgImage}
        availableLinks={availableLinks}
        availableTitle={availableTitle}
        productVariants={productVariants}
        productSize={productSize}
        productScent={productScent}
        packagingType={packagingType}
        waiSettings={waiSettings}
        cartProductsData={productDataForShopify}
        isShopify={isShopify}
        lang={lang}
        masterProduct={masterProduct}
        bvProductId={bvProductId}
        showBazaarvoiceReviewCta={showBazaarvoiceReviewCta}
      />
      <BodyRenderer
        bodyData={body}
        bodyStructure={bodyBlocks}
        bodyItemProps={{
          ...{
            tags,
            relatedProducts,
            relatedArticles,
            relatedArticlesLinks,
            singupFormBaner,
            anchorMenu,
            siteSettings,
            waiSettings,
            usePriceSpider: siteSettings?.usePriceSpider,
            isEanProductId: siteSettings?.isEanProductId,
            bvProductId,
          },
        }}
      />
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query ProductQuery(
    $link: String
    $relatedProductLinks: [String]
    $relatedArticlesLinks: [String]
    $productVariantLinks: [String]
  ) {
    productVariants: allProduct(filter: { link: { in: $productVariantLinks } }) {
      nodes {
        link
        ean
        productSize
        productScent
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $relatedProductLinks } }) {
      nodes {
        link
        ean
        useRedirects
        tags {
          isCategory
          isFamily
          color {
            label
          }
          isLabel
          title
        }
        title
        shortDescription
        cardDescription
        cardLink {
          url
        }
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    relatedArticles: allArticle(filter: { link: { in: $relatedArticlesLinks } }) {
      nodes {
        link
        tags {
          isCategory
          isLabel
          title
        }
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    page: allProduct(filter: { link: { eq: $link } }) {
      nodes {
        useRedirects
        skuId
        ean
        shopifyId
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        link
        title
        shortDescription
        bgImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        masterProduct {
          url
        }
        productSize
        productScent
        productInfo
        packagingType
        availableTitle
        availableLinks {
          properties {
            image {
              logo {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 150) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
              ariaLabel
            }

            link {
              queryString
              url
              target
            }
          }
        }
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        anchorMenu {
          linkToSection
          name
          hasBg
        }
        tags {
          color {
            label
          }
          isFamily
        }
        body {
          ... on TOverview {
            ...FragmentOverview
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TBenefits {
            ...FragmentBenefits
          }
          ... on TProductListingBanner {
            ...FragmentProductListingBanner
          }
          ... on TFAQSection {
            ...FragmentFaqSection
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TInstructionsList {
            ...FragmentInstructionsList
          }
          ... on TAnchorMenuPlaceholder {
            ...FragmentAnchorMenu
          }
          ... on TRelatedArticles {
            ...FragmentRelatedArticles
          }
          ... on THowToUse {
            ...FragmentHowToUse
          }
          ... on TImageTextGrid {
            ...FragmenTImageTextGrid
          }
          ... on TBazaarvoiceReviews {
            ...FragmentBazaarvoiceReviews
          }
        }
        defaultCompositions {
          purchaseSettings {
            ...FragmentPurchaseSettings
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          productSettings {
            availableLabel
            sizeLabel
            scentLabel
            subtitlePlaceholder
            bazaarvoiceSeeReviewLabel
          }
          waiSettings {
            ariaPrev
            ariaNext
            skipText
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
        }
        showBazaarvoiceReviewCta
      }
    }
  }
`;
