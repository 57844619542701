import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { Link } from 'react-scroll';
import { ANCHOR_LINKS_SETTINGS } from 'utils/constants';
import { ProductPageTypes } from '@shared/types/umbraco/content/productPage';
import './AnchorMenu.scss';

const AnchorMenu: FC<ProductPageTypes.IAnchorMenu> = ({ anchorMenu, anchorTitle }) => {
  const { isMobile } = useScreenRecognition();
  const offset = isMobile ? 64 : 96;

  return anchorMenu?.length > 0 ? (
    <div className="anchor-menu" data-testid="anchor-menu">
      <Container fluid>
        <div className="anchor-menu__flex">
          <div className="anchor-menu__flex-center">
            {anchorTitle ? (
              <strong className="anchor-menu__title">
                {anchorTitle}
                {isMobile ? `(${anchorMenu.length}):` : ':'}
              </strong>
            ) : null}

            <ul className="anchor-menu__list">
              {anchorMenu.map(({ name, linkToSection, hasBg }) => (
                <li key={name}>
                  <Link
                    to={linkToSection}
                    {...ANCHOR_LINKS_SETTINGS}
                    offset={hasBg ? 0 : offset * -1}
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentAnchorMenu on TAnchorMenuPlaceholder {
    properties {
      anchorTitle
    }
    structure
  }
`;

export default AnchorMenu;
