import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import useVariants from 'hooks/useVariants';
import ProductVariant from './components/ProductVariant';
import { IProductVariants } from './models';
import './ProductVariants.scss';

const ProductVariants: FC<IProductVariants> = ({
  productVariants,
  productSize = '',
  productScent = '',
  scentLabel,
  sizeLabel,
  lang,
}) => {
  const { selected, sizes, scents, isDeadLock, clickHandler } = useVariants(
    productVariants,
    productSize,
    productScent
  );

  const sizesArray = useMemo(
    () =>
      Object.keys(sizes).sort((a, b) =>
        a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
      ),
    [sizes]
  );
  const scentsArray = useMemo(
    () => Object.keys(scents).sort((a, b) => a.localeCompare(b, lang.split('-')[0])),
    [scents, lang]
  );

  return (
    <div className="product-variants" data-testid="product-variants">
      <div className="product-variants__grid">
        {sizesArray.length ? (
          <div
            className={classnames(
              'product-variants__grid-item product-variants__grid-item--sizes',
              { 'product-variants__grid-item--sizes-full': isDeadLock }
            )}
          >
            {sizeLabel ? <strong className="product-variants__label">{sizeLabel}</strong> : null}
            {sizesArray.map((size) => (
              <ProductVariant
                key={size}
                label={size}
                availableScents={sizes[size]}
                type="size"
                availableType="scent"
                current={selected}
                isDeadLock={isDeadLock}
                onSelected={clickHandler}
              />
            ))}
          </div>
        ) : null}
        {scentsArray.length ? (
          <div className="product-variants__grid-item product-variants__grid-item--scents">
            {sizeLabel ? <strong className="product-variants__label">{scentLabel}</strong> : null}
            {scentsArray.map((scent) => (
              <ProductVariant
                key={scent}
                label={scent}
                availableScents={scents[scent]}
                type="scent"
                availableType="size"
                current={selected}
                onSelected={clickHandler}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductVariants;
