import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import { ProductPageTypes } from '@shared/types/umbraco/content/productPage';
import './WTBCards.scss';
import Button from 'common/Button';

const WTBCards: FC<ProductPageTypes.IWTBCards> = ({ availableLabel, availableLinks }) => (
  <div className="wtb-cards" data-testid="wtb-cards">
    {availableLabel ? <strong className="wtb-cards__label">{availableLabel}</strong> : null}
    <div className="wtb-cards__links">
      {availableLinks.map(
        ({
          properties: {
            image: { logo, ariaLabel, imageAlt },
            link,
          },
        }) => (
          <Button
            to={link?.[0]?.queryString ? link?.[0]?.url + link[0].queryString : link?.[0]?.url}
            target={link?.[0]?.target}
            key={imageAlt + ariaLabel}
            variant="retailer"
            ariaLabel={ariaLabel}
          >
            <GatsbyImage
              image={logo}
              objectFit="contain"
              className="wtb-cards__image"
              alt={imageAlt}
            />
          </Button>
        )
      )}
    </div>
  </div>
);

export default WTBCards;
